<template>
  <div id="personalAwardIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.keyword" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index)  in data" :key="index">
        <van-cell>
          <template #title>
            <div class="custom-title  ">{{row.awardName.length >=19 ?(row.awardName.substring(0,16)+"..."):row.awardName }}</div>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <span @click="toStuDeatil(row)"> <svg-icon icon-class="yhxx" size="18" /> {{row.stuName+" "+ row.stuNo}}</span> <van-icon name="arrow" color="#23AB72" @click="toDetail(row)" />
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="rq" size="18" /> 获奖日期: {{parseTime(row.awardAt, "{y}-{m}-{d}")}}
            </div>
            <div class="custom-centent margin6 hidden-text" style="width:60%">
              <svg-icon icon-class="bfdw" size="18" /> 颁发单位: {{row.awardDept}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <div class="ckeckbox-st" @click="checkHandel(row)">
              <van-checkbox v-model="row.checked" icon-size="21px" shape="square"></van-checkbox>
            </div>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <div v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>
            <div v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>
            <div v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>
            <div v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>

          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="disableBtn(row.approvalStatus,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="danger">删除</van-button>
            <van-button class="table-edit-btn" :disabled="disableBtn(row.approvalStatus,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div class="batch-button">
      <van-row>
        <van-col span="5">
          <van-checkbox class="batch-checked" @click="selcetAll" v-model="checkedAll" checked-color="#23AB72">全选</van-checkbox>
        </van-col>
        <van-col span="9">
          <div class="batch-checked-text" v-html="selectionText"></div>
        </van-col>
        <van-col span="10">
          <van-button round block type="primary" color="#23AB72" :disabled="this.selections.length ===0" @click="batchAudit()">批量审批</van-button>
        </van-col>
      </van-row>
    </div>
    <van-dialog v-model="showAudit" title="审批" :showConfirmButton="false" width="90%">
      <div @click="toCloseAudit(false)" class="cancel">取消</div>
      <van-form ref="form">
        <van-field v-model="audit.appendData.opinion" :border="true" rows="3" label="审核意见" type="textarea" maxlength="50" placeholder="请输入审核意见" show-word-limit :rules="[{ required: true, message: '请输入审核意见' }]" />
      </van-form>
      <van-row style="padding:10px 10px 20px 10px" justify="center">
        <van-col span="8" align="center">
          <van-button type="warning" :loading="auditLoading1" :disabled="auditDisabled1" round style="width:80%" @click="confirmAudit(8)">驳回</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="danger" :loading="auditLoading2" :disabled="auditDisabled2" round style="width:80%" @click="confirmAudit(9)">拒绝</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="primary" :loading="auditLoading3" :disabled="auditDisabled3" round style="width:80%" @click="confirmAudit(1)">同意</van-button>
        </van-col>
      </van-row>
    </van-dialog>
    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import batchAudit from "@/mixins/batchAudit";
import { del, edit } from "@/api/modules/student/personalAward";

export default {
  name: "MyAwards",
  mixins: [initData, batchAudit],
  dicts: ["xj_shzt"],
  data() {
    return {
      audit: {
        appendData: {
          opinion: "同意",
          like: [],
        },
        coreKey: "",
        type: "XSGL",
        callback: "api/personal/awardInfo/approve",
        mkCode: "HJ",
        coreCode: "HJXXSH",
        status: 9,
      },
    }
  },
  created() { },
  methods: {
    beforeInit() {
      this.url = "api/personal/awardInfo";
      this.sort = ["approvalStatus,asc", "createTime,asc"];
      // 设置审批字段
      this.spIdField = "approvalStatus"
      // 初始化条件
      this.beforeRefresh()
      return true;
    },
    beforeRefresh() {

    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/a02/detail",
        query: data,
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/xs/a02/form",
        query: data,
      });
    },
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          del([data.id]).then((res) => {
            this.$notify({
              type: "success",
              message: "操作成功",
              duration: 2000,
            });
            this.toQuery();
            this.$set(data, "delLoading", false);
          });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    stuDisableBtn() {
      return false;
    },
    async confirmAudit(type) {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      let value = []
      if (roles.indexOf('自身') > -1) {
        value = this.selections.filter((item) => {
          return ['0', '1', '101', '2'].indexOf(item.approvalStatus + '') > -1
        })
      }
      if (roles.indexOf('本级') > -1) {
        value = this.selections.filter((item) => {
          return ['2', '3', '201'].indexOf(item.approvalStatus + '') > -1
        })
      }
      if (roles.indexOf('全部') > -1) {
        value = this.selections.filter((item) => {
          return ['3', '9', '301'].indexOf(item.approvalStatus + '') > -1
        })
      }
      this.audit.status = type
      if (value.length > 0) {
        await this.$refs.form.validate().then(
          (res) => {
            this.loadingStatus(type)
            this.selections.forEach((element) => {
              this.audit.coreKey = element.id;
              this.auditApi(this.audit).then((resp) => {
                if (resp.success) {
                }
              });
            });

          },
          (err) => { }
        );
        await this.toCloseAudit(true);
        this.toQuery()
      } else {
        this.$notify({
          type: "success",
          message: "请选择需要自己审核的数据",
          duration: 2000,
        });
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ckeckbox-st {
  font-size: 21px;
  top: 50%;
  position: absolute;
  right: 5%;
}

.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}
</style>